<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table v-bind:items="rows" v-bind:fields="suitsFields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped small hover @row-clicked="onRowClicked" v-bind:busy="loading"
             @sort-changed="onSortChanged" responsive
             :tbody-tr-class="rowClass"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline></b-form-checkbox>
      </template>
      <template #cell(lastUpdate)="data">
        {{data.value | moment($t('timeFormat.date.format'))}}
      </template>
      <template #cell(keyWords)="data">
        {{ data.value.length }}
      </template>
      <template #cell(section)="data">
        <template v-if="data.item.isBlackListed">
          <i class="fa-solid fa-circle" v-b-tooltip :title="$t('suitLog.excluded')"></i>
        </template>
        <template v-if="!data.item.isSentenceCompliance">
          {{ data.value }}
        </template>
        <template v-else-if="data.item.parentFound">
          <i class="fa-solid fa-circle-chevron-up"  v-b-tooltip :title="$t('suitLog.sentenceCompliance')"></i>
          {{ data.item.parentSection }}
        </template>
        <template v-else>
          <i class="fa-solid fa-circle text-danger" v-b-tooltip :title="$t('suitLog.mainSuitNotFound')"></i>
          {{ data.item.section }}
        </template>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"></b-form-checkbox>
      </template>
      <template #cell(sentence)="data">
        <b-badge v-if="data.value != null && sentenceDesc(data.value).visible" class="w-100" v-bind:variant="sentenceDesc(data.value).variant">
          {{ sentenceDesc(data.value).label }}
        </b-badge>
      </template>
      <template #cell(sentenceAmount)="data">
        <span v-if="data.value != null && data.value !== ''">R$ {{ data.value.toLocaleString($t('lang.full'))}}</span>
      </template>
      <template #cell(source)="data">
        <span v-if="data.value != null && data.value !== ''" :title="data.value">{{data.value.split('-')[0]}}</span>
      </template>
      <template #cell(lastReview)="data">
        <p class="m-0" v-if="data.value != null && data.value.userId != null">
          <b-avatar
              class="selectable mx-1"
              size="20"
              variant="light-primary"
              :src="getUserAvatar(data.value.userId)"
              v-b-tooltip
              :title="getUserFullName(data.value.userId)"

          />
          {{data.value.reviewedDate | moment($t('timeFormat.dateTime.format'))}}
        </p>
      </template>
    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-bind:disabled="loading"
            v-model="pagination.page"
            v-bind:total-rows="pagination.paginationTotal"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
            v-bind:last-class="pagination.total == null ? 'd-none' : ''"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="onPageClick">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select><span v-if="pagination.total != null">/{{ pagination.total }} {{$t('suits')}}</span>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinTable from '@/components/mixin/mixinTable'
import mixinNotifications from '@/components/mixin/mixinNotifications'

const SENTENCE_DESC = {
  JULGO_PROCEDENTE:{variant:"success",label:"PROCEDENTE", visible: true},
  JULGO_IMPROCEDENTE:{variant:"danger",label:"IMPROCEDENTE", visible: true},
  JULGO_PARCIALMENTE_PROCEDENTE:{variant:"warning",label:"PARCIALMENTE", visible: true},
  SENTENCE_COMPLIANCE_PHRASE:{variant:"info",label:"CUMPRIMENTO", visible: true},
  UNDEFINED:{visible: false},
}

export default {
  name: 'SuitsTable',
  mixins:[mixinBase,mixinTable,mixinNotifications],
  data: () => ({
    rows: [],
    selected:{},
  }),
  props: {
    filters: {
      default: () => ({})
    },
    fields:{
      default: () => ({})
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1
        this.refresh()
      }
    }
  },
  created() {
    this.setPagination(1,0,10);
    this.refresh()
  },
  computed: {
    ...mapGetters('data', ['getUserFullName','getUserAvatar']),
    suitsFields() {
      let $this = this;
      let fieldsFilter = this.fields;
      let fields = [
        { key: 'selection', label: '', sortable: false, active: fieldsFilter["selection"] !== false },
        { key: 'id', label: $this.$t('columns.id'), sortable: true, active: fieldsFilter["id"] !== false },
        { key: 'lastUpdate', label: $this.$t('columns.lastUpdate'), sortable: true, active: fieldsFilter["lastUpdate"] !== false },
        { key: 'section', label: $this.$t('columns.section'), sortable: false, active: fieldsFilter["section"] !== false },
        { key: 'subsection', label: $this.$t('columns.subsection'), sortable: false, active: fieldsFilter["subsection"] !== false },
        { key: 'complainant', label: $this.$t('columns.complainant'), sortable: false, active: fieldsFilter["complainant"] !== false },
        { key: 'defendant', label: $this.$t('columns.defendant'), sortable: true, active: fieldsFilter["defendant"] !== false },
        { key: 'sentence', label: $this.$t('columns.sentence'), sortable: true, active: fieldsFilter["sentence"] !== false },
        { key: 'sentenceAmount', label: $this.$t('columns.sentenceAmount'), sortable: true, active: fieldsFilter["sentenceAmount"] !== false },
        { key: 'keyWords', label: $this.$t('columns.keyWords'), sortable: false, active: fieldsFilter["keyWords"] !== false },
        { key: 'points', label: $this.$t('columns.points'), sortable: true, active: fieldsFilter["points"] !== false },
        { key: 'source', label: $this.$t('columns.source'), sortable: true, active: fieldsFilter["source"] !== false },
        { key: 'summary', label: $this.$t('columns.summary'), sortable: true, active: fieldsFilter["summary"] !== false },
        { key: 'lastReview', label: $this.$t('columns.lastReview'), sortable: true, active: fieldsFilter["lastReview"] !== false },
        // { key: 'operations', label: '', sortable: false, active: fieldsFilter["operations"] !== false },
      ]
      return fields.filter(f => f.active === true);
    },
    sentenceDesc(){
      return (value) => SENTENCE_DESC[value] || SENTENCE_DESC.UNDEFINED;
    },
    selectedIds() {
      this.refreshKey // Little hack to recompile
      console.log("selectedIds()",this.selected)
      return Object.keys(this.selected).filter(k=> k !== "all")
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('suits', ['getAllSuits']),
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...this.sort }
      if(sort.field == null){
        sort.field = "lastUpdate";
      }
      this.getAllSuits(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      ).then(result=>{
        $this.rows = result.data
        $this.setMetaDataFromResult(result.metadata);
        $this.setLoaded(loadingToken);
      }).catch(err=> {
        $this.showErrorNotification($this.$t('suits') + " - erro", $this.getErrorDescription(err));
        $this.setLoaded(loadingToken);
      });
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 'awesome') return 'table-success'
    },
  }
}
</script>

<style scoped>

</style>
