
export default {
  methods: {
    closeOthersPopovers (id) {
       this.$root.$emit('bv::hide::popover', id)
    },
    closeAllPopovers () {
        this.$root.$emit('bv::hide::popover')
    }
  }
}
