<template>
  <div class="suit-log p-1" style="">
    <h5>Texto </h5>
    <div class="position-absolute" style="top:-15px; right: 0" size="sm">
      <b-form-checkbox style="margin-top: 30px" v-model="log.details.wrapText" switch>
        Recortar texto
      </b-form-checkbox>
    </div>
    <p style="font-family: 'Verdana'" v-if="log.details.wrapText === true"
       v-html="parseText(log.defendant, log.details.text)"
    ></p>
    <p style="font-family: 'Verdana'" v-else v-html="highlightText(log.details.text)"></p>
  </div>
</template>

<script>

const TEXT_WITH_POINTS = ["art.", "n.", "eg.", "-se.", "nº.",  "inc.", "arts.", ".com", ".Com", "Sra.", "Sr.", "Fls.", "fl.", "Fl."]
const SENTENCE_REGEX = /(JULGO PROCEDENTE EM PARTE|JULGO PROCEDENTE|JULGO PARCIALMENTE PROCEDENTE|JULGO EXTINTO)/g;

export default {
  name: 'suitLog',
  props: ['value','sentenceAmountText', 'keyWords'],
  data: () => ({ log: {details:{}} }),
  watch:{
    value:{
      handler(){
        if(this.value != null && this.value.details != null){
          this.log=this.value;
        }
      },
      immediate: true
    },
  },
  methods:{
    highlightText(text){
      if(text == null || text === "") {return  "";}
      text = text.replace(SENTENCE_REGEX,"<span style='background-color: #ffe600'>$1</span>");
      let sentence = (SENTENCE_REGEX.exec(text) || [])[0];
      // Highlight sentence amount *after* the sentence word
      if(sentence != null && this.sentenceAmountText != null){
        let sentenceIndex = text.indexOf(sentence);
        let regex = new RegExp(`(${this.sentenceAmountText.replaceAll("$","\\$")})`,'guim');
        text = text.substr(0, sentenceIndex) +  text.substr(sentenceIndex).replace(new RegExp(regex,'g'),"<span style='background-color: #2dff00'>$1</span>");
      }
      // Highlight keyWords
      if(Array.isArray(this.keyWords) && this.keyWords.length > 0){
        let regex = new RegExp(`(${this.keyWords.join('|').replaceAll("$","\\$")})`,'guim');
        text = text.replace(new RegExp(regex,'g'),"<span style='background-color: #8bfaf1'>$1</span>");
      }
      return text;
    },
    parseText(defendant, text){
      if(defendant == null){
        defendant = "";
      }
      let processDetailsIndex = text.indexOf(defendant) + defendant.length;
      let processDetails = text.substr(0, processDetailsIndex) ;
      let processText = text.substr(processDetailsIndex);
      return this.highlightText(processDetails + processText.split('.')
          .reduce(this.concatText));
    },
    concatText(ans, text){
      if(ans.includes("- Diante de todo") || text.includes("valor de")) {
      }
      if(ans !== ''){
        ans += '.';
      }
      if(this.isATextNumberOrUppercase(ans.trim()) !== true && TEXT_WITH_POINTS.filter(t=>ans.endsWith(t)).length <= 0){
        ans += '<br/>';
      }
      return ans + text
    },
    isATextNumberOrUppercase(text){
      if(text == null || text.length <= 0){
        return text;
      }
      let lastChar = text.substr(text.length - 2,text.length-1);
      return !isNaN(parseInt(lastChar)) || lastChar.toUpperCase() === lastChar;
    }
  }
}
</script>

<style lang="scss">

.suit-log {
  color: #333333;
  background-color: #ebebf2
}

.dark-layout{
  .suit-log {
    color: #a3a4a8;
    background-color: #242b3d; //#242b3d
  }
}

</style>
