<template>
  <b-modal  no-fade v-model="dialog.show" size="xl" :ref="`madal-suit-${uniqueId}`" :id="`madal-suit-${uniqueId}`"
            :hide-footer="true" centered
            no-close-on-backdrop
  >
    <template #modal-title>
      {{suit.id}} - {{suit.complainant || '?'}} X {{suit.defendant}}
    </template>
    <div v-if="loading !== true">
      <b-tabs align="center">
        <b-tab :title="$t('suitModal.titles.details')" active>
          <suit-details v-model="suit"/>
          <b-row class="mt-2">
            <b-col cols="12">
              <h5>{{$t('logs')}}</h5>
            </b-col>
            <b-col cols="12">
              <div class="border" v-for="(log,index) in suit.suitLogs" v-bind:key="`${index}-${log.details.date}`">
                <b-button block v-b-toggle="`accordion-log-${index}-${log.details.date}`" variant="outline-secondary">
                  <div class="text-left">
                    <p class="d-inline-block m-0">{{$t('suitLog.journalDesc', { date: log.details.date, pageNumber: log.details.pageNumber})}}</p>
                    <b-badge variant="success" class="float-right d-inline mx-1" v-if="log.sentence !== 'UNDEFINED'"> {{$t('suitLog.sentence')}}</b-badge>
                    <b-badge variant="info" class="float-right d-inline" v-if="Array.isArray(log.keyWords) && log.keyWords.length > 0"> {{$t('suitLog.keyWords')}}</b-badge>
                  </div>
                </b-button>
                <b-collapse v-bind:id="`accordion-log-${index}-${log.details.date}`" accordion="accordion-log" role="tabpanel">
                  <b-card-body class="p-0 position-relative" >
                    <suit-log v-bind:value="log" v-bind:sentence-amount-text="suit.sentenceAmountText" v-bind:keyWords="suit.keyWords"/>
                  </b-card-body>
                </b-collapse>
              </div>
            </b-col>
          </b-row>
          <div class="mt-2 text-center">
            <b-button variant="outline-success" @click="saveSuit"><i class="fa-regular fa-floppy-disk mx-1" ></i> {{$t('save')}}</b-button>
            <slot name="footer-actions" v-bind:suit="suit"/>
          </div>
        </b-tab>
        <b-tab :title="$t('suitModal.titles.notes')">
          <notes v-bind:suit-id="suitId" autoload/>
        </b-tab>
        <b-tab :title="$t('suitModal.titles.files')" style="min-height: 300px">
          <div class="text-center position-absolute d-block w-100" style="top:50%">
            <b-alert show variant="danger" >
              <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
              Não disponivel
            </b-alert>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
      <b-alert show variant="danger" >
        <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
        {{ error }}
      </b-alert>
    </div>
    <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
      <b-spinner>{{$t('operations.loading')}}</b-spinner>
    </div>
  </b-modal>
</template>

<script>

import { mapActions } from 'vuex'
import SuitLog from '@/components/partial/suits/suitLog'
import SuitDetails from '@/components/partial/suits/suitDetails'
import Notes from '@/components/partial/notes/notes'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'suitManagedModal',
  components: { Notes, SuitDetails, SuitLog },
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    suitId: '',
    suit: {}
  }),
  watch: {},
  created() {
    this.uniqueId = this._uid
  },
  methods: {
    ...mapActions('suits', ['getSuitById']),
    showModal(id) {
      this.loading = true
      this.suit = {};
      this.suitId = id
      this.dialog.show = true
      this.refreshData()
      this.loading = false
    },
    closeModal() {
      this.dialog.show = false;
      this.suit = {}
    },
    async refreshData() {
      this.suit = await this.getSuitById(this.suitId)
    },
    saveSuit() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: "Não disponivel",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }
}
</script>

<style scoped>

</style>
