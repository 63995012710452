import { render, staticRenderFns } from "./SuitsTable.vue?vue&type=template&id=062290c8&scoped=true&"
import script from "./SuitsTable.vue?vue&type=script&lang=js&"
export * from "./SuitsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062290c8",
  null
  
)

export default component.exports