import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SuitsFilterCard from '@/components/partial/suits/SuitsFilterCard'
import SuitModal from '@/components/modal/suitModal'
import SuitManagedModal from '@/components/modal/suitManagedModal'
import SuitsTable from '@/components/tables/SuitsTable'

export default {
  components: {
    SuitsFilterCard,
    SuitModal,
    SuitManagedModal,
    SuitsTable
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapActions('suits', ['refreshData', 'updateSuitMonitoring']),
    onSuitSelected(id) {
      this.$refs['suit-modal'].showModal(id)
    },
    async sendToMonitoring(ids,type){
      console.log("sendToMonitoring", {ids,type})
      if(!Array.isArray(ids)){
        ids = Object.keys(ids).filter(suitId=>ids[suitId] === true);
      }
      for(let suitId of ids){
        try {
          await this.updateSuitMonitoring({
            suitId,
            status: type
          });
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: err.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
      this.$refs['suit-modal'].closeModal()
      this.$refs.suitsTable.refresh();
    }
  }
}
