<template>
  <div id="note">
    <div v-if="loaded">
      <div role="tablist">
        <!--TODO:-->
        <template v-if="Array.isArray(notes)">
          <note-console v-model="newNote" v-bind:notes="notes" @deleteNote="deleteNote" @loadNote="loadNote"
                        @resetNewNote="resetNewNote" @save="save"
          />
        </template>
        <template v-else>
          <b-alert show variant="warning" class="text-center">
            <i class="fas fa-exclamation-triangle mr-3"></i>
            <strong>{{ $t('No Note Type set') }}</strong>
            <br/>
          </b-alert>
        </template>
      </div>
    </div>
    <div v-else-if="error != ''">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="text-align: center;height: 100%;padding: 30% 20px 20px;">
        <b-spinner label="Spinning"/>
        {{ $t('loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NoteConsole from './noteConsole'

export default {
  name: 'notes',
  // eslint-disable-next-line vue/no-unused-components
  components: { NoteConsole },

  props: {
    autoload: {
      default: false,
      type: Boolean
    },
    suitId: String,
    historyMaxHeight: {
      default: 600,
      type: Number
    },
    defaultTitle: {
      required: false,
      default: null,
      type: String
    },
  },
  watch: {
    suitId() {
      this.reset()
      if (this.autoload == true) {
        this.loadData()
      }
    },
    notes() {
      setTimeout(this.notesToBottom, 500)
    }
  },
  data() {
    let $this = this
    return {
      loaded: true,
      error: '',
      newNote: {
        suitId: $this.suitId,
        title: '',
        text: '',
        isPublic: true,
      },
      notes: [{
        id: 1,
        title: 'test',
        text: 'test',
        isPublic: true,
        allPermissionUser: []
      }],
      //Listeners
      listeners: {
        load: () => {
        }
      }
    }
  },
  computed: {
    uniqueNotes() {
      let notes = Object.values(this.notes)
      if (notes == null || notes.length == 0) {
        return []
      }
      return notes[0]
    },

  },
  created() {
    this.loadData();
    this.$root.$on('crm::client::note::load', (this.listeners.load = () => {
      //TODO: CREATE REFRESH TYPE ON TAB CLICK
      if (this.loaded !== true) {
        this.reset()
      }
      this.loadData()
    }))
  },

  beforeDestroy() {
    this.$root.$off('crm::client::note::load', this.listeners.load)
  },
  methods: {
    ...mapActions('notes', ['getNotes', 'get', 'update', 'register', 'deleteNote']),
    speechEnd({
      sentences,
      text
    }) {
      console.log('text', text)
      console.log('sentences', sentences)
      this.newNote.text = sentences
    },
    reset() {
      this.loaded = false
      this.error = ''
      this.notes = {}
      this.resetNewNote()
    },
    resetNewNote() {
      this.newNote = {
        suitId: this.suitId,
        title: '',
        text: '',
        isPublic: true,
      }
    },
    loadData() {
      let $this = this
      this.$emit('update-start', true)
      this.getNotes({
        suitId: $this.suitId,
        pagination: {
          page: 1,
          size: 999
        }
      })
          .then(
              (noteData) => {
                $this.notes = noteData.data
                $this.loaded = true
                //Reactivity in Depth
                $this.$emit('update-finish', false)
              },
              (e) => {
                $this.error = e
                $this.$emit('update-finish', false)
              }
          )
    },
    notesToBottom() {
      /* var container = this.$el.querySelector(".note-history");
       container.scrollTop = container.scrollHeight;*/
      /*let $ = window.$
      $('.note-history')
          .each(function () {
            $(this)
                .scrollTop(this.scrollHeight)
          })*/
    },
    loadNote(id) {
      console.log("loadNote")
      if (id == null) {
        this.resetNewNote()
      }else if (id !== this.newNote.id) {
        this.get({ id }).then((n) => {this.newNote = n})
      }
    },
    deleteNote() {
      console.log('removeNote')
      this.deleteNote(this.newNote.id)
          .then(this.onNoteRemoved)
    },
    onNoteRemoved() {
      this.resetNewNote()
      this.loadData()
    },
    save() {
      this.$root.$emit('bv::hide::popover')
      let $this = this
      if (this.newNote.id == null) {
        this.register({ note: this.newNote })
            .then(() => {
              $this.loadData()
              $this.resetNewNote()
            })
      } else {
        this.update({
          id: this.newNote.id,
          note: this.newNote
        })
            .then(
                () => {
                  let i = $this.notes.findIndex(n => n.id == $this.newNote.id)
                  if (i != -1) {
                    $this.notes[i] = $this.newNote
                  }
                  $this.loadData()
                  $this.resetNewNote()
                },
                (e) => {
                })
      }
    },

  }
}
</script>

<style>
#note {
  min-height: 500px;
}

#note .note .note-detail .note-title {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 0;
  font-size: 12px;
  padding: 5px 0;
}

#note .note .note-detail .note-title .note-user {
  text-align: right;
}

#note .note .note-detail .note-title > div {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

#note .note .note-detail .note-text {
  width: 100%;
  margin: 0;
  font-size: 14px;
  padding: 0 7px;

}

#note .note .note-detail .note-time {
  text-align: right;
  font-size: 10px;
  opacity: 0.8;
}

</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
