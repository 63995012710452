<template>
  <b-modal  no-fade v-model="dialog.show" size="xl" :ref="`madal-suit-${uniqueId}`" :id="`madal-suit-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>
      {{suit.id}} - {{suit.complainant || '?'}} X {{suit.defendant}}
    </template>
    <div style="min-height: 600px; padding-bottom: 3rem">
      <div v-if="loading !== true">
        <suit-details v-model="suit" @parent-select="onParentSelected"/>
        <b-row class="mt-1">
          <b-col cols="12">
            <h5>{{$t('logs')}}</h5>
          </b-col>
          <b-col cols="12">
            <div class="border" v-for="(log,index) in suit.suitLogs" v-bind:key="`${index}-${log.details.date}`">
              <b-button size="sm" block v-b-toggle="`accordion-log-${index}-${log.details.date}`" variant="outline-secondary">
                <div class="text-left">
                  <p class="d-inline-block m-0">{{$t('suitLog.journalDesc', { date: log.details.date, pageNumber: log.details.pageNumber})}}</p>
                  <b-badge variant="success" class="float-right d-inline mx-1" v-if="log.hasArt513 === true"> {{$t('suitLog.art513')}}</b-badge>
                  <b-badge variant="success" class="float-right d-inline mx-1" v-if="log.hasArt523 === true"> {{$t('suitLog.art523')}}</b-badge>
                  <b-badge variant="success" class="float-right d-inline mx-1" v-if="log.sentence !== 'UNDEFINED'"> {{$t('suitLog.sentence')}}</b-badge>
                  <b-badge variant="info" class="float-right d-inline" v-if="filterKeyWords(log.keyWords).length > 0" v-b-tooltip v-bind:title="filterKeyWords(log.keyWords).join(',')"> {{$t('suitLog.keyWords')}}</b-badge>
                  <b-badge variant="warning" class="float-right d-inline mx-1" v-if="suit.parentFound === true && log.id===suit.parentId"> {{$t('suitLog.mainSuit')}}</b-badge>
                </div>
              </b-button>
              <b-collapse v-bind:id="`accordion-log-${index}-${log.details.date}`" accordion="accordion-log" role="tabpanel">
                <b-card-body class="p-0 position-relative" >
                  <suit-log v-bind:value="log" v-bind:sentence-amount-text="suit.sentenceAmountText" v-bind:keyWords="filterKeyWords(suit.keyWords)"/>
                </b-card-body>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
        <div class="mt-2 text-center" style="position: absolute; bottom: 1rem; width: 100%">
          <slot name="footer-actions" v-bind:suit="suit"/>
        </div>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
        <b-alert show variant="danger" >
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>

import { mapActions } from 'vuex'
import SuitLog from '@/components/partial/suits/suitLog'
import SuitDetails from '@/components/partial/suits/suitDetails'
import mixinBase from '@/components/mixin/mixinBase'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'suitModal',
  components: { SuitDetails, SuitLog },
  mixins: [mixinBase],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    suitId: '',
    error: null,
    suit: {}
  }),
  watch: {
    ['queryParams']: function (newVal, oldVal) {
      this.verifyQueryParam();
    }
  },
  created() {
    this.uniqueId = this._uid
    this.verifyQueryParam();
  },

  methods: {
    ...mapActions('suits', ['getSuitById']),
    verifyQueryParam(){
      console.log('verifyQueryParam', this.queryParams.id)
      if (this.queryParams.id == null) {
        this.closeModal()
      } else if(this.suitId !== this.queryParams.id) {
        this.showModal(this.queryParams.id)
      }
    },
    showModal(id) {
      this.loading = true
      this.suit = {};
      this.suitId = id
      this.dialog.show = true
      this.refreshData();
      this.pushState({id: id })
    },
    closeModal(){
      this.dialog.show = false;
      this.suit = {}
    },
    onDialogClose(){
      this.closeModal()
      this.pushState({id: null })
    },
    refreshData() {
      let $this = this;
      this.getSuitById(this.suitId).then(suit => {
        $this.suit = suit;
        $this.error = null
        $this.loading = false
      }, err => {
        $this.error = $this.getErrorDescription(err)
        $this.loading = false
      })
    },
    filterKeyWords(keyWords) {
      if(Array.isArray(keyWords) && keyWords.length > 0){
        return keyWords.filter(k => k != null && k !== '' && !(/Cumprimento de sentença/.test(k)))
      }
      return [];
    },
    onParentSelected(suitId) {
      this.showModal(suitId)
    },
    saveSuit(){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: "Não disponivel",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }
}
</script>

<style scoped>

</style>
